<template>
  <div
    class="form-no-messages"
    style="position:relative"
  >
    <v-container class="pt-0">
      <v-row class="ma-0 pa-0">
        <v-col
          class="pa-0"
          cols="6"
        >
          <v-dialog
            ref="pickingDialog"
            v-model="modal"
            class="date-picker"
            :return-value.sync="pickingDate"
            :width="datePickerWidth"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="pickingDate"
                style="width:160px;"
                label="피킹기준일"
                prepend-icon="mdi-calendar"
                readonly
                autocomplete="off"
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="pickingDate"
              locale="ko-ko"
              @input="onPickingDateInput"
            />
          </v-dialog>
        </v-col>
        <v-spacer />
        <v-col
          class="mt-0 pb-0"
          cols="4"
          align="right"
        >
          <v-btn
            depressed
            color="primary"
            @click="onClickPickingEnd"
          >
            피킹 완료
          </v-btn>
        </v-col>
        <!--
        <v-col
          class="pa-0"
          cols="6"
        >
          <v-select
            v-model="selectedInType"
            :items="inTypes"
            item-text="noDlvprps"
            item-value="noDlvprps"
            menu-props="auto"
            label="배송ID"
            prepend-icon="mdi-map"
            persistent-hint
            return-object
            single-line
            @input="onPickIdIput"
          />
        </v-col>
        -->
      </v-row>
      <dx-data-grid
        ref="refPickingDetailGrid"
        :data-source="pickingInfoList"
        :show-borders="true"
        :show-row-lines="false"
        :show-column-lines="true"
        :hover-state-enabled="true"
        :allow-column-resizing="true"
        column-resizing-mode="widget"
        :allow-column-reordering="true"
        :row-alternation-enabled="true"
        :height="gridHeight"
        :focused-row-enabled="true"
        key-expr="uuid"
        :focused-row-key.sync="focusedRowKeyPicking"
        @focused-row-changed="onSelectionChangedPickingOrder"
        @toolbar-preparing="onToolbarPreparingDetail"
        @cell-prepared="onCellPreparedPicking"
      >
        <DxSearchPanel
          :visible="false"
          :width="120"
          placeholder="검색..."
        />
        <DxFilterRow
          :visible="false"
          apply-filter="auto"
        />
        <DxColumn
          :allow-editing="false"
          caption="No"
          data-field="noDlvprps"
          sort-order="asc"
          :min-width="54"
        />
        <DxColumn
          :allow-editing="false"
          caption="품명"
          data-field="품명"
          :min-width="180"
        />
        <DxColumn
          :allow-editing="false"
          caption="요청수량"
          data-field="요청수량"
          :min-width="70"
        />
        <DxColumn
          :allow-editing="false"
          caption="피킹수량"
          data-field="피킹수량"
          :min-width="70"
        />
        <DxColumn
          :allow-editing="false"
          caption="품번"
          data-field="cdItem"
          :min-width="140"
        />
        <DxSummary>
          <DxTotalItem
            value-format="#,##0"
            column="cdItem"
            display-format="{0} (건)"
          />
        </DxSummary>
        <dx-paging :enabled="false" />
        <dx-scrolling
          mode="virtual"
          show-scrollbar="always"
          :use-native="true"
        />
        <dx-sorting mode="multiple" />
      </dx-data-grid>
      <loading
        :active.sync="isLoading"
        color="rgb(2, 110, 156)"
        loader="bars"
      />
    </v-container>
    <div
      class="left-draw-wrap"
    >
      <v-app-bar
        id="left-draw-app-bar"
        dark
        height="50px"
      >
        <v-icon
          large
          @click="onClickBefore"
        >
          mdi-chevron-left
        </v-icon>
        <v-toolbar-title style="margin-left:10px;">
          완제품 피킹
        </v-toolbar-title>
      </v-app-bar>
      <div
        v-if="selectedRowPickingOrder"
        class="left-draw-content"
      >
        <v-container>
          <div class="content-input-wrap">
            <div
              v-if="$store.getters.getMobileModel !== 'PDA' && showQrScaner==='block'"
              class="qrcode-wrap"
              :style="{display: showQrScaner}"
            >
              <QrCodeScanComponent @scan-result="onPCodeScan" />
            </div>
            <v-row>
              <v-col
                cols="6"
              >
                <v-text-field
                  v-model="selectedRowPickingOrder.noDlvprps"
                  label="배송제안ID"
                  :readonly="true"
                  filled
                  autocomplete="off"
                />
              </v-col>
              <v-col
                cols="6"
              >
                <v-text-field
                  v-model="selectedRowPickingOrder.요청수량"
                  label="요청수량"
                  :readonly="true"
                  filled
                  autocomplete="off"
                />
              </v-col>
            </v-row>
            <v-text-field
              v-model="selectedRowPickingOrder.cdItem"
              label="품번"
              :readonly="true"
              filled
              autocomplete="off"
            />
            <v-text-field
              v-model="selectedRowPickingOrder.품명"
              label="품명"
              :readonly="true"
              filled
              autocomplete="off"
            />
          </div>
          <v-row dense>
            <v-col
              :cols="$store.getters.getMobileModel !== 'PDA' ? 5 : 6"
            >
              <v-text-field
                id="input-qrkey"
                v-model="qrKey"
                label="파레트 번호"
                autocomplete="off"
                placeholder="QR 스캔"
                clearable
                @keyup.enter.prevent="onEnterPcode"
              />
            </v-col>
            <v-col
              v-if="$store.getters.getMobileModel !== 'PDA'"
              class="pb-0 pt-4 pl-0"
              cols="1"
            >
              <v-icon
                @click="showQrScaner = showQrScaner === 'none' ? 'block' : 'none'"
              >
                mdi-camera
              </v-icon>
            </v-col>
            <v-spacer />
            <v-col
              cols="5"
            >
              <v-text-field
                id="input-qty"
                v-model="takeQty"
                label="피킹수량"
                type="number"
                clearable
                autocomplete="off"
                @keyup.enter.prevent="onEnterTakeQty"
              />
            </v-col>
          </v-row>
          <div style="margin-top:10px; background-color:black;">
            <dx-data-grid
              ref="refGoodsInputGrid"
              :data-source="palletInfoList"
              :show-borders="true"
              :show-row-lines="false"
              :show-column-lines="true"
              :hover-state-enabled="true"
              :allow-column-resizing="true"
              column-resizing-mode="widget"
              :allow-column-reordering="true"
              :row-alternation-enabled="true"
              :height="gridHeightSub"
              :focused-row-enabled="true"
              :focused-row-key.sync="focusedRowKeyPallet"
              :auto-navigate-to-focused-row="false"
              key-expr="seqId"
              @focused-row-changed="onSelectionChanged"
            >
              <DxColumn
                :allow-editing="false"
                caption="NO"
                data-field="입고키"
                sort-order="desc"
                :min-width="60"
              />
              <DxColumn
                :allow-editing="false"
                caption="품번"
                data-field="품번"
                :min-width="100"
              />
              <DxColumn
                :allow-editing="false"
                caption="잔량"
                data-field="현재수량"
                format="#,##0"
                :min-width="80"
              />
              <DxColumn
                :allow-editing="true"
                caption="유효일"
                data-field="유효일"
                data-type="date"
                format="yy/MM/dd"
                :min-width="100"
              />
              <DxColumn
                :allow-editing="false"
                caption="품명"
                data-field="품명"
                :min-width="120"
              />
              <dx-paging :enabled="false" />
              <dx-scrolling
                mode="virtual"
                show-scrollbar="always"
                :use-native="true"
              />
              <dx-sorting mode="multiple" />
            </dx-data-grid>
          </div>
          <v-row
            dense
            class="mt-2"
          >
            <v-col
              cols="12"
            >
              <v-btn
                width="100%"
                color="primary"
                @click="onClickSave"
              >
                피킹 등록
              </v-btn>
            </v-col>
          </v-row>
          <div style="margin-top:14px; background-color:black;">
            <dx-data-grid
              ref="refGoodsOutputGrid"
              :data-source="outputListInfo"
              :show-borders="true"
              :show-row-lines="false"
              :show-column-lines="true"
              :hover-state-enabled="true"
              :allow-column-resizing="true"
              column-resizing-mode="widget"
              :allow-column-reordering="true"
              :row-alternation-enabled="true"
              :height="gridHeightSub"
              :focused-row-enabled="false"
              @row-removing="onRowRemoving"
              @row-removed="onRowRemoved"
            >
              <DxColumnFixing :enabled="true" />
              <DxEditing
                :allow-deleting="true"
                :allow-updating="false"
                :allow-adding="false"
                :use-icons="true"
                mode="row"
              />
              <DxColumn
                caption="파레트"
                data-field="입고키"
                :min-width="60"
              />
              <DxColumn
                :visible="false"
                caption="품번"
                data-field="품번"
                :min-width="100"
              />
              <DxColumn
                caption="피킹"
                data-field="수량"
                format="#,##0"
                :min-width="60"
              />
              <DxColumn
                caption="유효일"
                data-field="동종재고코드"
                :min-width="80"
              />
              <DxColumn
                width="50"
                :buttons="editButtons"
                type="buttons"
              />
              <dx-paging :enabled="false" />
              <dx-scrolling
                mode="virtual"
                show-scrollbar="always"
                :use-native="true"
              />
              <dx-sorting mode="multiple" />
            </dx-data-grid>
          </div>
        </v-container>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from 'vue-loading-overlay'
import AppLib from '../../share/AppLib'
import ConstDef from '../../../../BnhMesVueWin/src/share/ConstDef'
import DxDataGrid, {
  DxScrolling, DxTotalItem, DxSearchPanel, DxFilterRow, DxColumnFixing, DxEditing,
  DxSummary, DxColumn, DxPaging, DxSorting
} from 'devextreme-vue/data-grid'
import QrCodeScanComponent from '../../components/QrCodeScanComponent'
import HttpService from '../../share/service/HttpService'
// import AesCrypto from '../../share/service/AesCrypto'

export default {
  name: 'EmptView',
  components: {
    Loading,
    DxDataGrid,
    DxScrolling,
    DxTotalItem,
    DxSummary,
    DxColumn,
    DxPaging,
    DxSorting,
    DxSearchPanel,
    DxFilterRow,
    DxColumnFixing,
    DxEditing,
    QrCodeScanComponent
  },
  props: {
    pickingOption: {
      type: String,
      default: 'POST'
    }
  },
  data: function () {
    return {
      pickingDate: null,
      isLoading: false,
      userInfo: null,
      htmlWdth: 800,
      modal: false,
      datePickerWidth: '350px',
      selectedInType: {},
      inTypes: [],
      pickingInfoList: null,
      focusedRowKeyPicking: null,
      selectedRowPickingOrder: null,
      gridHeight: null,
      editButtons: ['delete'],
      // qrcode scan
      showQrScaner: 'none',
      qrKey: null,
      palletInfoList: null,
      focusedRowKeyPallet: null,
      takeQty: 0,
      selectedRowPallet: null,
      // pickingresult grid
      outputListInfo: null,
      gridHeightSub: null
    }
  },
  created () {
    this.userInfo = this.$store.getters.getUserInfo
    if (this.userInfo === undefined || this.userInfo === null) {
      this.$router.push('/')
    }
    this.pickingDate = AppLib.getToday()
  },
  mounted () {
    this.gridHeight = (this.$Q('html').height() - 120)
    this.htmlWdth = this.$Q('html').width()
    window.addEventListener('resize', this.handleResize)
    this.$Q('.left-draw-wrap').css('left', `${this.htmlWdth}px`)
    this.$Q('.left-draw-wrap').css('height', `${this.$Q('html').height()}px`)
    this.refreshPickingList()
  },
  updated () {
  },
  methods: {
    handleResize (event) {
      this.htmlWdth = this.$Q('html').width()
      if (this.$Q('.left-draw-wrap').css('left') !== '0px') {
        this.$Q('.left-draw-wrap').css('left', `${this.htmlWdth}px`)
      }
      this.$Q('.left-draw-wrap').css('height', `${this.$Q('html').height()}px`)
      this.gridHeight = (this.$Q('html').height() - 120)
      const html = this.$Q('html').height()
      const drawContentHeight = this.$Q('.content-input-wrap').height()
      this.gridHeightSub = (html - drawContentHeight - 190) / 2
    },
    onCellPreparedPicking (e) {
      if (e.rowType === 'data' && e.column.dataField === 'noDlvprps') {
        if (e.data.sapUpdated === true) {
          this.$Q(e.cellElement).css('color', 'red')
        }
      }
    },
    onClickPickingEnd (e) {
      if (this.pickingInfoList.length === 0) {
        return
      }
      if (this.$_.findIndex(this.pickingInfoList, { sapUpdated: false }) < 0) {
        this.$snotify.info('이미 전품목 피킹 완료 되었습니다.')
        return
      }
      const 요청합계 = this.pickingInfoList.reduce((s, f) => {
        return s + parseInt(f.요청수량)
      }, 0)
      const 피킹합계 = this.pickingInfoList.reduce((s, f) => {
        return s + parseInt(f.피킹수량)
      }, 0)
      if (요청합계 !== 피킹합계) {
        this.$snotify.error('전품목 피킹 완료 후 종료하여 주세요.')
        return
      }
      this.$snotify.confirm('피킹 완료 하시겠습니까? (완료 후 삭제할 수 없습니다.)', '', {
        timeout: 10000,
        showProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        buttons: [
          {
            text: '완료',
            action: (toast) => {
              this.$snotify.remove(toast.id)
              this.$_sp.runUpdateSqlProc('피킹지시서', ['dtDlvprps'], null, [{ dtDlvprps: this.pickingDate, 피킹완료: true }])
                .then((data) => {
                  // TODO picking upload to byd
                  HttpService.reqPost({ date: this.pickingDate }, '/updateToSapPostPicking')
                  this.$snotify.info('전품목 피킹 완료 하였습니다.')
                  this.refreshPickingList()
                })
            }
          },
          {
            text: '취소',
            action: (toast) => { this.$snotify.remove(toast.id) }
          }
        ]
      })
    },
    onSelectionChangedPickingOrder (e) {
      if (e != null && e.row === undefined) {
        return
      }
      if (e !== null) {
        this.selectedRowPickingOrder = e.row.data
        if (this.selectedRowPickingOrder.sapUpdated) {
          this.$snotify.info('이미 피킹 완료 되었습니다.')
          return
        }
        const vm = this
        this.$Q('.left-draw-wrap').animate({
          left: '0'
        }, 800, function () {
          vm.$Q('#left-draw-app-bar').css('position', 'fixed')
          vm.$Q('.left-draw-content').css('padding-top', '50px')
          const html = vm.$Q('html').height()
          const drawContentHeight = vm.$Q('.content-input-wrap').height()
          vm.gridHeightSub = (html - drawContentHeight - 190) / 2
          vm.focusedRowKeyOrder = null
          vm.refreshOutputList()
          setTimeout(() => {
            vm.$Q('#input-qrkey').focus()
          }, 100)
        })
      }
    },
    onToolbarPreparingDetail (e) {
    },
    onPickIdIput (e) {
      this.refreshPickingList()
    },
    onPickingDateInput () {
      this.$refs.pickingDialog.save(this.pickingDate)
      console.log(this.pickingDate)
      this.refreshPickingList()
    },
    refreshPickingList () {
      this.isLoading = true
      const param = {
        date: this.pickingDate,
        option: this.pickingOption
      }

      this.$_sp.runNoEncodeFindProc('spFindAllPickingListByDateAndOption', param)
        .then((data) => {
          this.isLoading = false
          this.pickingInfoList = this.$_sp.MakeModel(data)
          console.log(this.pickingInfoList)
          if (this.pickingInfoList.length > 0) {
            if (this.$_.findIndex(this.pickingInfoList, { sapUpdated: false }) < 0) {
              this.$snotify.info(`${this.pickingDate}일은 피킹 완료된 상태 입니다.`)
            }
          }
        })
        .catch(error => {
          this.isLoading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    refreshOutputList () { // 출고결과 조회
      this.isLoading = true
      this.$_sp.runNoEncodeFindSqlProc('창고수불', { 발주번호: this.selectedRowPickingOrder.uuid })
        .then((data) => {
          this.isLoading = false
          this.outputListInfo = this.$_sp.MakeModel(data)
        })
    },
    // left draw view
    // left slide view
    initInput () {
      this.qrKey = null
      this.takeQty = null
      this.focusedRowKeyPallet = null
      this.palletInfoList = null
      this.selectedRowPallet = null
    },
    onEnterPcode (e) {
      if (this.qrKey === null || this.qrKey.length === 0) return
      let split = null
      split = AppLib.base64DecodeSubstring(this.qrKey)
      this.palletInfoList = null
      this.qrKey = parseInt(split)
      console.log(this.selectedRowPickingOrder.요청수량)
      this.$_sp.runNoEncodeFindProc('spFindAllReceiptByKeyAndInput', { 입고키: this.qrKey, 입출고구분: ConstDef.자재입고 })
        .then((data) => {
          const temp = this.$_sp.MakeModel(data)
          console.log(this.selectedRowPickingOrder)
          console.log(temp)
          this.selectedRowPallet = this.$_.findWhere(temp, { 품번: this.selectedRowPickingOrder.cdItem })
          if (this.selectedRowPallet === undefined) {
            this.$snotify.info('해당 파레트번호로 검색된 품목이 없습니다.')
            this.initInput()
          } else {
            this.palletInfoList = temp
            this.focusedRowKeyPallet = this.selectedRowPallet.seqId
            const sum = this.outputListInfo.reduce((s, f) => {
              return s + parseInt(f.수량)
            }, 0)
            console.log('sum= ' + sum)
            if (this.selectedRowPickingOrder.요청수량 - sum <= this.selectedRowPallet.현재수량) {
              this.takeQty = this.selectedRowPickingOrder.요청수량 - sum
            } else {
              this.takeQty = this.selectedRowPallet.현재수량
            }
            this.$Q('#input-qty').focus()
          }
        })
        .catch(error => {
          this.isLoading = false
          this.initInput()
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    onEnterTakeQty (e) {

    },
    onClickBefore () {
      this.focusedRowKeyPicking = null
      this.$Q('#left-draw-app-bar').css('position', 'static')
      this.$Q('.left-draw-content').css('padding-top', '0px')
      this.$Q('.left-draw-wrap').animate({
        left: `${this.htmlWdth}px`
      }, 500)
    },
    onClickSave () {
      if (this.takeQty === null || this.takeQty === 0) {
        this.$snotify.error('피킹 수량을 입력하여 주세요.')
        return
      }
      const sum = this.outputListInfo.reduce((s, f) => {
        return s + parseInt(f.수량)
      }, 0)
      if (this.takeQty > this.selectedRowPickingOrder.요청수량 - sum) {
        this.$snotify.error('피킹 합계 수량이 요청수량보다 많습니다.')
        this.$Q('#input-qty').focus()
        return
      }
      if (this.selectedRowPallet === null) {
        this.$snotify.info('검색된 파레트가 없습니다.')
        return
      }
      const param = {
        입고키: this.qrKey,
        입출고구분: ConstDef.제품출하,
        품번: this.selectedRowPickingOrder.cdItem,
        품명: this.selectedRowPickingOrder.품명,
        수량: this.takeQty,
        발주번호: this.selectedRowPickingOrder.uuid,
        유효일: this.selectedRowPallet.유효일,
        동종재고코드: this.selectedRowPallet.동종재고코드,
        장소위치코드: this.selectedRowPallet.장소위치코드
      }
      this.isLoading = true
      this.$_sp.runInsertAndFindSqlProc('창고수불', ['발주번호'], null, param)
        .then((data) => {
          this.isLoading = false
          param.seqId = this.$_sp.MakeModel(data)[0].seqId
          this.refreshPickingList()
          this.$snotify.info('저장 되었습니다.')
          this.initInput()
          this.outputListInfo.push(param)
        })
        .catch(error => {
          this.isLoading = false
          this.initInput()
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    onPCodeScan (result) {
      this.qrKey = result
      this.onEnterPcode(null)
    },
    onSelectionChanged (e) {
      if (e != null && e.row === undefined) {
        return
      }
      if (e !== null) {
        this.selectedRowPallet = e.row.data
      }
    },
    // onRowRemoving출고 리스트
    onRowRemoving (row) {
      console.log(this.selectedRowPickingOrder)
      if (this.selectedRowPickingOrder.피킹완료) {
        this.$snotify.info('피킹 완료처리 후 에는 삭제할 수 없습니다.')
        row.cancel = true
      }
    },
    onRowRemoved (row) {
      this.isLoading = true
      this.$_sp.runDeleteSqlProc('창고수불', [{ seqId: row.data.seqId }])
        .then((data) => {
          this.isLoading = false
          const sum = this.outputListInfo.reduce((s, f) => {
            return s + parseInt(f.수량)
          }, 0)
          this.takeQty = this.selectedRowPickingOrder.요청수량 - sum
          this.refreshPickingList()
        })
    }
  }
}
</script>

<style lang="scss">
  .left-draw-content {
    background-color: black;
  }
</style>
